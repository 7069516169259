import { defineStore } from 'pinia'
import getAllRoutes from '~/composables/AppApiRoutes'

export const useNotificationStore = defineStore('notifications', () => {
  const unReadCount = ref<number>(0)

  const getUnReadCount = computed(() => unReadCount.value)

  async function fetchUnRead() {
    try {
      const data = await $fetch<ApiResponse<{ notification: number }>>(
        getAllRoutes().notificationsRoutes.getUndreadNotificationsByServer
      )

      unReadCount.value = data.data?.notification || 0
    } catch (error) {
      unReadCount.value = 0
    }
    return unReadCount.value
  }

  function setUnRead(count = 0) {
    unReadCount.value = count
  }

  function increaseUnRead() {
    unReadCount.value += 1
  }

  function decreaseUnRead() {
    if (unReadCount.value > 0) {
      unReadCount.value -= 1
    }
  }

  return {
    unReadCount: getUnReadCount,
    fetchUnRead,
    setUnRead,
    increaseUnRead,
    decreaseUnRead
  }
})
